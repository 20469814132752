import React from "react";

const topHeader = () => {
  return (
    <div className="top_header">
      <p>
        Summer Sale For All Swim Suits And Free Express Delivery - OFF 50%!{" "}
        <a href="#">ShopNow</a>
      </p>
    </div>
  );
};

export default topHeader;
