import slide1 from "../assets/images/hero.jpg";

const HeroSlider = [
  {
    id: 1,
    image: slide1,
  },
  {
    id: 2,
    image: slide1,
  },
];

export default HeroSlider;
