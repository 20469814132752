import React from "react";
import Banner from "../components/Banner";

const Contact = () => {
  return (
    <>
      <Banner page={`contact`} />
      <section className="contacts padding-50">
        <div className="container">
          <div className="mb-5">
            <h2 className="sec_title">Contact us</h2>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="name">Your Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder="Your name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="">Your Email Adress</label>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="example@gmail.com"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Molestias adipisci sunt harum unde aliquid voluptatem a!
              Recusandae soluta incidunt non, harum quam molestiae
              exercitationem, modi possimus laborum minus esse ad?
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
