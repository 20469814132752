import Camera from "../assets/images/categories/Category-Camera.svg";
import CellPhone from "../assets/images/categories/Category-CellPhone.svg";
import Computer from "../assets/images/categories/Category-Computer.svg";
import Gamepad from "../assets/images/categories/Category-Gamepad.svg";
import Headphone from "../assets/images/categories/Category-Headphone.svg";

const Categories = [
  {
    id: 1,
    name: "Woman’s Fashion",
    image: Camera,
  },
  {
    id: 2,
    name: "Men’s Fashion",
    image: CellPhone,
  },
  {
    id: 3,
    name: "Electronics",
    image: Computer,
  },
  {
    id: 4,
    name: "Home & Lifestyle",
    image: Gamepad,
  },
  {
    id: 5,
    name: "Medicine",
    image: Headphone,
  },
];

export default Categories;
