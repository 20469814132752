import React from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 mb-3 pr-3">
              <div className="brand-logo text-white mb-3">Exclusive</div>

              <div>
                <h4 className="text-white">Subscribe</h4>
                <p className="text-white">Get 10% off your first order</p>
                <form action="#">
                  <input
                    type="email"
                    name=""
                    placeholder="Enter your email"
                    className="form-control"
                    required
                    id=""
                  />

                  <button type="submit">Send</button>
                </form>
              </div>
            </div>

            <div className="col-lg-4 col-md-3 col-sm-12 mb-3">
              <h4 className="text-white mb-3">Support</h4>

              <ul>
                <li className="text-white">
                  111 Bijoy sarani, Dhaka, DH 1515, Bangladesh.
                </li>
                <li className="text-white">+88015-88888-9999</li>
                <li className="text-white">exclusive@gmail.com</li>
                <li className="text-white">support@mail.com</li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <h4 className="text-white mb-3">Pages</h4>

              <ul>
                <li className="text-white">Home</li>
                <li className="text-white">Products</li>
                <li className="text-white">About us</li>
                <li className="text-white">Contact us</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
