import React from "react";

const ProductCard = ({ prod }) => {
  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="card h-100 d-flex flex-column">
          <img src={prod.image} className="card-img-top" alt={prod.title} />
          <div className="card-body flex-grow-1">
            <h5 className="card-title">{prod.title}</h5>
            <div className="text-danger">
              {prod.price}{" "}
              <span className="old-price text-gray">{prod.oldPrice}</span>
            </div>
            <div className="stars">
              {Array.from({ length: prod.stars }).map((_, index) => (
                <i className="fa-solid fa-star" key={index}></i>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
