import React from "react";
import { Link } from "react-router-dom";

const Banner = ({ page }) => {
  return (
    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center gap-10">
            <Link to={`/`} className="text-decoration-none text-dark">
              Home
            </Link>
            <hr className="gray_line" />
            <Link
              to={`/${page.toLowerCase()}`}
              className="text-decoration-none text-dark"
            >
              {page}
            </Link>
          </div>

          <div className="col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
