import React, { useEffect } from "react";
import Banner from "../components/Banner";
import ProductsList from "../data/Products";
import Categories from "../data/Categories";
import Swiper from "swiper";
import { useNavigate } from "react-router-dom";
import ProductCard from "../components/ProductCard";

const Products = () => {
  useEffect(() => {
    const swiper = new Swiper(".categories .swiper-container", {
      slidesPerView: "auto",
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        780: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        0: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      },
    });
  }, []);

  return (
    <>
      <Banner page={`products`} />
      <section className="products padding-50">
        <div className="container">
          <div className="mb-5">
            <h2 className="sec_title">Explore Our Products</h2>
            <p className="sec_subtitle">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div>

          <div className="row d-flex flex-wrap justify-content-center align-items-stretch">
            {ProductsList.map((prod) => (
              <ProductCard prod={prod} key={prod.id} />
            ))}
          </div>
        </div>
      </section>

      <section className="categories padding-50">
        <div className="container">
          <div className="mb-5">
            <h2 className="sec_title">Browse By Category</h2>
          </div>

          <div className="swiper-container">
            <div className="swiper-wrapper">
              {Categories.map((cate) => (
                <div className="swiper-slide" key={cate.id}>
                  <div className="card">
                    <img
                      className="card-img-category"
                      src={cate.image}
                      alt=""
                    />
                    <div className="card-body text-center">
                      <h5 className="card-title m-0">{cate.name}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
