import img1 from "../assets/images/products/1.jpg";
import img2 from "../assets/images/products/2.jpg";
import img3 from "../assets/images/products/3.jpg";
import img4 from "../assets/images/products/4.jpg";
import img5 from "../assets/images/products/5.jpg";
import img6 from "../assets/images/products/6.jpg";
import img7 from "../assets/images/products/7.jpg";
import img8 from "../assets/images/products/8.jpg";

const Products = [
  {
    id: 1,
    title: "HAVIT HV-G92 Gamepad",
    oldPrice: "$120",
    price: "$160",
    stars: 5,
    sales: 8,
    image: img1,
  },
  {
    id: 2,
    title: "AK-900 Wired Keyboard",
    oldPrice: "$960",
    price: "$1160",
    stars: 5,
    sales: 8,
    image: img2,
  },
  {
    id: 3,
    title: "IPS LCD Gaming Monitor",
    oldPrice: "$400",
    price: "$370",
    stars: 4,
    sales: 8,
    image: img3,
  },
  {
    id: 4,
    title: "S-Series Comfort Chair",
    oldPrice: "$400",
    price: "$375",
    stars: 4,
    sales: 8,
    image: img4,
  },
  {
    id: 5,
    title: "Kids Electric Car",
    oldPrice: "$999",
    price: "$960",
    stars: 4,
    sales: 8,
    image: img5,
  },
  {
    id: 6,
    title: "Jr. Zoom Soccer Cleats",
    oldPrice: "$1160",
    price: "$960",
    stars: 4,
    sales: 8,
    image: img6,
  },
  {
    id: 7,
    title: "GP11 Shooter USB Gamepad",
    oldPrice: "$979",
    price: "$660",
    stars: 4,
    sales: 8,
    image: img7,
  },
  {
    id: 8,
    title: "Quilted Satin Jacket",
    oldPrice: "$969",
    price: "$660",
    stars: 5,
    sales: 8,
    image: img8,
  },
];

export default Products;
