import React, { useEffect } from "react";
import Products from "../data/Products";
import HeroSlider from "../data/HeroSlider";
import OwlCarousel from "react-owl-carousel";
import Categories from "../data/Categories";
import Swiper from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeadset,
  faTruckFast,
  faVialCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import ProductCard from "../components/ProductCard";

const Home = () => {
  useEffect(() => {
    const swiper = new Swiper(".categories .swiper-container", {
      slidesPerView: "auto",
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        780: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        0: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      },
    });
  }, []);

  useEffect(() => {
    const swiper = new Swiper(".flash-sales .swiper-container", {
      slidesPerView: "auto",
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 2,
        },
      },
    });
  }, []);

  useEffect(() => {
    const swiper = new Swiper(".hero .swiper-container", {
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }, []);

  const navigate = useNavigate();
  const handleNavigateToProduct = () => {
    navigate("/products");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="hero container padding-50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="categories_list">
                <ul>
                  {Categories.map((cate) => (
                    <li key={cate.id}>
                      <Link to="#" className="text-dark">
                        {cate.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 overflow-hidden">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  {HeroSlider.map((slide) => (
                    <div className="swiper-slide" key={slide.id}>
                      <img src={slide.image} alt="Image 1" />
                    </div>
                  ))}
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flash-sales padding-50">
        <div className="container">
          <div className="mb-5">
            <h2 className="sec_title">Flash Sales</h2>
            <p className="sec_subtitle">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div>

          <div className="row d-flex flex-wrap justify-content-center align-items-stretch">
            <div className="col-md-12">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  {Products.map((prod) => (
                    <div className="swiper-slide" key={prod.id}>
                      <div className="card h-100 d-flex flex-column">
                        <img
                          src={prod.image}
                          className="card-img-top"
                          alt={prod.title}
                        />
                        <div className="card-body flex-grow-1">
                          <h5 className="card-title">{prod.title}</h5>
                          <div className="text-danger">
                            {prod.price}{" "}
                            <span className="old-price text-gray">
                              {prod.oldPrice}
                            </span>
                          </div>
                          <div className="stars">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
            <div className="col-md-12 mx-auto d-flex my-4">
              <button className="btn mx-auto" onClick={handleNavigateToProduct}>
                View All Products
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="categories padding-50">
        <div className="container">
          <div className="mb-5">
            <h2 className="sec_title">Browse By Category</h2>
          </div>

          <div className="swiper-container">
            <div className="swiper-wrapper">
              {Categories.map((cate) => (
                <div className="swiper-slide" key={cate.id}>
                  <div className="card">
                    <img
                      className="card-img-category"
                      src={cate.image}
                      alt="Card image cap"
                    />
                    <div className="card-body text-center">
                      <h5 className="card-title m-0">{cate.name}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>

      <section className="products padding-50">
        <div className="container">
          <div className="mb-5">
            <h2 className="sec_title">Explore Our Products</h2>
            <p className="sec_subtitle">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div>

          <div className="row d-flex flex-wrap justify-content-center align-items-stretch">
            {Products.map((prod) => (
              <ProductCard prod={prod} key={prod.id} />
            ))}
          </div>
          <div className="row">
            <div className="col-md-12 mx-auto d-flex">
              <button className="btn mx-auto" onClick={handleNavigateToProduct}>
                View All Products
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="contacts padding-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
              <div className="card">
                <div className="contact-icon">
                  <FontAwesomeIcon
                    fontSize={20}
                    color={`#fff`}
                    icon={faTruckFast}
                  />
                </div>

                <div className="card-body">
                  <h4 className="card-title">FREE AND FAST DELIVERY</h4>
                  <p className="card-text">
                    Free delivery for all orders over $140
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
              <div className="card">
                <div className="contact-icon">
                  <FontAwesomeIcon
                    fontSize={20}
                    color={`#fff`}
                    icon={faHeadset}
                  />
                </div>

                <div className="card-body">
                  <h4 className="card-title">24/7 CUSTOMER SERVICE</h4>
                  <p className="card-text">Friendly 24/7 customer support</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
              <div className="card">
                <div className="contact-icon">
                  <FontAwesomeIcon
                    fontSize={20}
                    color={`#fff`}
                    icon={faVialCircleCheck}
                  />
                </div>

                <div className="card-body">
                  <h4 className="card-title">MONEY BACK GUARANTEE</h4>
                  <p className="card-text">We return money within 30 days</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
