import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faSearch } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const pages = ["home", "products", "contact", "about", "signup"];

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="brand-logo">Exclusive</div>
        <ul className="nav-items mb-0">
          {pages.map((page, index) => (
            <li className="nav-item" key={index}>
              <NavLink
                to={
                  page.toLowerCase() === "home" ? "/" : `/${page.toLowerCase()}`
                }
                className="text-dark text-decoration-none"
              >
                {capitalizeFirstLetter(page)}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="nav-end">
          <form action="#">
            <input
              type="text"
              name="search"
              placeholder="What are you looking for?"
              className="form-input"
            />
            <FontAwesomeIcon icon={faSearch} />
          </form>
          <div className="icon">
            <FontAwesomeIcon icon={faHeart} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
